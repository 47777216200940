<template>
    <div>
        <Header :id="id"></Header>
        <div class="nbanner">
          <img :src="banner" alt="">
        </div>
        <div class="nnews">
          <div class="nnews2">
            <div class="ntitle">{{ $t('news') }}</div>
              <div class="news-item" v-for="(item,index) in newslist" :key="index">
                  <div class="newsimg">
                    <router-link  :to="{ name: 'NewsDetail', params: { newsId: item.newsId,id: id }}">
                      <img :src="item.image" alt="">
                    </router-link>
                  </div>
                  <div class="newsinfo">
                    <router-link :to="{ name: 'NewsDetail', params: { newsId: item.newsId,id: id }}">
                    <div class="newsinfo1">{{item.title}}</div>
                    <div class="newsinfo2" v-html="item.content">
                          {{item.content}}
                    </div>
                     </router-link>
                    <div class="newsinfo3">{{item.updatedAt}}</div>
                  </div>
               
              </div>
            <!-- 分页 -->
            <div class="page">
              <a-pagination
                showQuickJumper
                :defaultCurrent="pagination.defaultCurrent"
                :defaultPageSize="pagination.defaultPageSize"
                :total="pagination.total"
                @change="onChange"
                @showSizeChange="onShowSizeChange"
              />
            </div>
          </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>

import Header from "./components/Header";
import Footer from "../components/Footer";

export default {
  name: "News",
  components: {
    Footer,
    Header,
  },
  data () {
    return {
      page: 1,
      id: this.$route.params.id,
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
      },
      newslist: [],
      banners: [],
      banner: '',
      token: localStorage.token
    }
  }, 
  mounted () {
    if (this.token == '') {
      this.$layer.msg('请先登录');
      this.$router.go(-1)
    }
    this.getList()
    this.getList2()
  },
  methods: {
    //分页
    onShowSizeChange (current, pageSize) {
      this.pagination.defaultCurrent = 1
      this.pagination.defaultPageSize = pageSize
      this.getList() // 显示列表的接口名称
    },
    onChange(current, size) {
      this.pagination.defaultCurrent = current
      this.pagination.defaultPageSize = size
      // 查数据
      this.getList()
    },
    // 获取展会详情
    getList () {
      const params = {
        page: this.pagination.defaultCurrent,
        pagesize: this.pagination.defaultPageSize
      }
      this.$common.fetchList('/article/lists', Object.assign({}, params)).then(data => {
        if (data.code === 200) {
          const result = data.data
          const pagination = { ...this.pagination }
          pagination.total = result.totalInfo.total_items
          this.newslist = result.lists
          this.pagination = pagination
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
     // 展会官网首页
    getList2 () { 
       this.$common.fetchList('/exhibition/index?exhibitionId='+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          for(const [k, v] of Object.entries(result)) {
            this[k] = v
          }
          this.banner = this.banners[0].mediaUrl
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/news.less';
</style>